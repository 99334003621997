<template>
  <div v-if="colleague">
    <!-- Mobile layout -->
    <responsive-layout v-if="$vuetify.breakpoint.mobile">
      <v-sheet
        style="min-height: 100vh"
        class="d-flex flex-column justify-center transparent"
        dark
      >
        <div class="main py-8 flex-fill">
          <v-container fluid>
            <!-- 'Breadcrumb' navigation at the top -->
            <v-row justify="start" align="start" class="mt-5 lobby-router-row">
              <v-col
                cols="12"
                class="pl-0 d-flex align-start justify-start flex-column ml-1"
              >
                <v-btn
                  class="ma-2 white--text lobby-back-button rounded-0 mr-0 mb-0"
                  color="black"
                >
                  <router-link class="white--text" to="/meet-your-colleagues">
                    <v-icon left dark color="pink" class="back-arrow-theatre">
                      fas fa-chevron-left
                    </v-icon>
                    <span class="back-arrow-lobby-link">Back</span>
                  </router-link>
                </v-btn>
              </v-col>
            </v-row>

            <!-- Downloads section -->
            <v-row
              justify="start"
              align="start"
              class="mt-5 sessions-agenda-lobby-row"
            >
              <!-- Speaker bios section -->

              <v-col cols="12">
                <div class="py-6 d-flex white pr-4">
                  <v-col cols="12" class="pl-2 pr-2">
                    <v-col
                      cols="12"
                      class="
                        agenda-container
                        flex-sm-column
                        d-flex
                        white
                        pl-2
                        pt-1
                        userpage-image-container
                      "
                    >
                      <v-col cols="12">
                        <v-img
                          :src="colleague.photo"
                          style="max-width: 100%"
                        ></v-img>
                      </v-col>

                      <v-col cols="12" class="text-left">
                        <p class="mt-2 mb-4 userpage-name">
                          {{ colleague.name }}
                        </p>
                        <v-btn
                          color="black"
                          class="
                            ma-2
                            ml-1
                            white--text
                            mobile-navigation-button
                            rounded-0
                            elevation-0
                          "
                        >
                          <v-icon left dark color="pink">
                            fas fa-comments
                          </v-icon>
                          <button @click="onChatClick()">
                            <span
                              class="userpage-button-text"
                              v-if="!$vuetify.breakpoint.mobile"
                              >CHAT</span
                            >
                          </button>
                        </v-btn>
                        <v-btn
                          color="black"
                          class="
                            ma-2
                            white--text
                            mobile-navigation-button
                            rounded-0
                            elevation-0
                          "
                          :href="`mailto:${colleague.email}`"
                        >
                          <v-icon left dark color="pink">
                            fas fa-envelope
                          </v-icon>
                          <span
                            class="userpage-button-text"
                            v-if="!$vuetify.breakpoint.mobile"
                            >E-mail</span
                          >
                        </v-btn>

                        <v-col
                          cols="12"
                          class="
                            d-flex
                            flex-column flex-wrap
                            align-start
                            justify-start
                            pl-1
                            mt-6
                          "
                        >
                          <span class="pl-0 interests-text-my-profile mb-0"
                            >Interests:</span
                          >

                          <v-row
                            justify="start"
                            align="start"
                            class="mt-3 mb-3 sessions-agenda-lobby-row ml-0"
                          >
                            <v-btn
                              v-for="(interest, idx) in colleague.interests"
                              :key="idx"
                              label="Sort by"
                              active-class="filter-buttons-my-profile-active"
                              class="
                                rounded-0
                                ma-1
                                ml-0
                                filter-buttons
                                white--text
                                grey
                                elevation-0
                                filter-buttons-my-profile
                              "
                            >
                              {{ interest }}
                            </v-btn>
                          </v-row>
                        </v-col>

                        <v-col
                          cols="12"
                          class="
                            d-flex
                            flex-column flex-wrap
                            align-start
                            justify-start
                            pl-1
                          "
                        >
                          <span class="pl-0 interests-text-my-profile mb-3"
                            >Store Name:</span
                          >
                          <p class="completed-profile-text">
                            {{ colleague.store_name }}
                          </p>
                        </v-col>

                        <v-col
                          cols="12"
                          class="
                            d-flex
                            flex-column flex-wrap
                            align-start
                            justify-start
                            pl-1
                          "
                        >
                          <span class="pl-0 interests-text-my-profile mb-3"
                            >Registration Type:</span
                          >
                          <p class="completed-profile-text">
                            {{ colleague.registration_type }}
                          </p>
                        </v-col>

                        <v-col
                          cols="12"
                          class="
                            d-flex
                            flex-column flex-wrap
                            align-start
                            justify-start
                            pl-1
                          "
                        >
                          <span class="pl-0 interests-text-my-profile mb-3"
                            >Territory Manager:</span
                          >
                          <p class="completed-profile-text">
                            {{ colleague.territory_manager }}
                          </p>
                        </v-col>
                        <v-col
                          cols="12"
                          class="
                            d-flex
                            flex-column flex-wrap
                            align-start
                            justify-start
                            pl-1
                          "
                        >
                          <span class="pl-0 interests-text-my-profile mb-3"
                            >About Me:</span
                          >
                          <p class="completed-profile-text">
                            {{ colleague.about }}
                          </p>
                        </v-col>
                      </v-col>
                    </v-col>
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-sheet>
    </responsive-layout>

    <!-- 16:9 layout for desktop devices -->
    <aspect-ratio-layout v-else>
      <v-sheet class="d-flex flex-column transparent" light>
        <div class="main">
          <v-container fluid>
            <!-- 'Breadcrumb' navigation at the top -->
            <v-row
              justify="start"
              align="start"
              class="mt-5 lobby-router-row d-flex"
            >
              <v-col
                cols="12"
                class="d-flex align-start justify-start flex-column ml-8"
              >
                <v-btn
                  class="ma-2 white--text lobby-back-button rounded-0 mr-0 mb-0"
                  color="black"
                >
                  <router-link class="white--text" to="/meet-your-colleagues">
                    <v-icon left dark color="pink" class="back-arrow-theatre">
                      fas fa-chevron-left
                    </v-icon>
                    <span class="back-arrow-lobby-link">Back</span>
                  </router-link>
                </v-btn>
              </v-col>
            </v-row>

            <!-- Downloads section -->
            <v-row
              justify="start"
              align="start"
              class="mt-5 sessions-agenda-lobby-row"
            >
              <!-- Speaker bios section -->

              <v-col cols="8">
                <div class="d-flex white">
                  <v-col cols="12" style="padding: 0px">
                    <v-col
                      cols="12"
                      class="
                        agenda-container
                        d-flex
                        white
                        pl-2
                        pt-1
                        userpage-image-container
                      "
                    >
                      <v-col cols="4" class="pl-0">
                        <v-img
                          :src="colleague.photo"
                          style="max-width: 100%"
                        ></v-img>
                      </v-col>

                      <v-col cols="8" class="text-left">
                        <p class="mt-2 mb-4 userpage-name">
                          {{ colleague.name }}
                        </p>
                        <v-btn
                          color="black"
                          class="
                            ma-2
                            ml-1
                            white--text
                            mobile-navigation-button
                            rounded-0
                            elevation-0
                          "
                        >
                          <v-icon left dark color="pink">
                            fas fa-comments
                          </v-icon>
                          <button @click="onChatClick()">
                            <span
                              class="userpage-button-text"
                              v-if="!$vuetify.breakpoint.mobile"
                              >CHAT</span
                            >
                          </button>
                        </v-btn>
                        <v-btn
                          color="black"
                          class="
                            ma-2
                            white--text
                            mobile-navigation-button
                            rounded-0
                            elevation-0
                          "
                          :href="`mailto:${colleague.email}`"
                        >
                          <v-icon left dark color="pink">
                            fas fa-envelope
                          </v-icon>
                          <span
                            class="userpage-button-text"
                            v-if="!$vuetify.breakpoint.mobile"
                            >E-mail</span
                          >
                        </v-btn>

                        <v-col
                          cols="12"
                          class="
                            d-flex
                            flex-column flex-wrap
                            align-start
                            justify-start
                            pl-1
                            mt-6
                          "
                        >
                          <span class="pl-0 interests-text-my-profile mb-0"
                            >Interests:</span
                          >

                          <v-row
                            justify="start"
                            align="start"
                            class="mt-3 mb-3 sessions-agenda-lobby-row ml-0"
                          >
                            <v-btn
                              v-for="(interest, idx) in colleague.interests"
                              :key="idx"
                              label="Sort by"
                              active-class="filter-buttons-my-profile-active"
                              class="
                                rounded-0
                                ma-1
                                ml-0
                                filter-buttons
                                white--text
                                grey
                                elevation-0
                                filter-buttons-my-profile
                              "
                            >
                              {{ interest }}
                            </v-btn>
                          </v-row>
                        </v-col>

                        <v-col
                          cols="12"
                          class="
                            d-flex
                            flex-column flex-wrap
                            align-start
                            justify-start
                            pl-1
                          "
                        >
                          <span class="pl-0 interests-text-my-profile mb-3"
                            >Store Name:</span
                          >
                          <p class="completed-profile-text">
                            {{ colleague.store_name }}
                          </p>
                        </v-col>

                        <v-col
                          cols="12"
                          class="
                            d-flex
                            flex-column flex-wrap
                            align-start
                            justify-start
                            pl-1
                          "
                        >
                          <span class="pl-0 interests-text-my-profile mb-3"
                            >Registration Type:</span
                          >
                          <p class="completed-profile-text">
                            {{ colleague.registration_type }}
                          </p>
                        </v-col>

                        <v-col
                          cols="12"
                          class="
                            d-flex
                            flex-column flex-wrap
                            align-start
                            justify-start
                            pl-1
                          "
                        >
                          <span class="pl-0 interests-text-my-profile mb-3"
                            >Territory Manager:</span
                          >
                          <p class="completed-profile-text">
                            {{ colleague.territory_manager }}
                          </p>
                        </v-col>
                        <v-col
                          cols="12"
                          class="
                            d-flex
                            flex-column flex-wrap
                            align-start
                            justify-start
                            pl-1
                          "
                        >
                          <span class="pl-0 interests-text-my-profile mb-3"
                            >About Me:</span
                          >
                          <p class="completed-profile-text">
                            {{ colleague.about }}
                          </p>
                        </v-col>
                      </v-col>
                    </v-col>
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-sheet>
    </aspect-ratio-layout>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import ResponsiveLayout from "../layouts/ResponsiveLayout.vue";
import AspectRatioLayout from "../layouts/16by9Layout.vue";
import theatreMockData from "../assets/data/theatre-mock-data.json";
import downloadsMockData from "../assets/data/information-downloads-mock-data.json";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ResponsiveLayout,
    AspectRatioLayout,
  },

  data() {
    return {
      dialog: false,
      dialogFirst: false,
      dialogSecretSecond: false,
      dialogSecretThird: false,
      column: null,
      row: null,
      overlay: false,
      mockData: theatreMockData,
      downloadsMockData: downloadsMockData,
      colleague: null,
    };
  },

  async mounted() {
    await this.$store.dispatch("getColleagues");
    this.colleague = await this.$store.dispatch(
      "getColleague",
      this.$route.params.id
    );
  },

  computed: {
    ...mapState(["isChatOpen"]),
    ...mapGetters(["me"]),
  },

  watch: {
    "$store.getters.isAuthenticated": async function (newValue, oldValue) {
      console.log("isAuthenticated change:", newValue, "oldValue:", oldValue);
      if (newValue) {
        await this.onLogin();
      }
    },
  },

  methods: {
    onChatClick() {
      if (!this.isChatOpen) {
        this.$store.dispatch("toggleChat");
      }

      this.$store.commit("SET_ACTIVE_CHAT_ID", this.colleague.id);
    },
  },
};
</script>

<style scoped>
.main {
  background-image: url("https://vod.eventcdn.net/pandora/smc2021/MeetYourColleagues_Bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@media screen and (min-width: 1020px) {
  .main {
    background-image: url("https://vod.eventcdn.net/pandora/smc2021/MeetYourColleagues_Bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    backface-visibility: hidden;
  }
}

.v-application.livehouse-app .countdown-value {
  font-size: 5rem !important;
  color: var(--v-primary-base) !important;
  line-height: 5rem !important;
  font-weight: 700 !important;
}

@media screen and (max-width: 992px) {
  .v-application.livehouse-app .countdown-value {
    font-size: 3rem !important;
    line-height: 3.5rem !important;
  }
}

.countdown-with-pink-text {
  color: var(--pink) !important;
  font: var(--unnamed-font-style-normal) normal bold 80px/60px
    var(--unnamed-font-family-pan-text);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal bold 80px/60px Pan Text;
}

.countdown-headline {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) 22px/32px
    var(--unnamed-font-family-pan-text);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal normal 22px/32px Pan Text;
}

.unit {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-18) /
    var(--unnamed-line-spacing-22) var(--unnamed-font-family-pan-text);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--darker-grey);
  text-align: center;
  font: normal normal normal 18px/22px Pan Text;
}

.countdown-container {
  margin-top: 10.5rem;
}
</style>
